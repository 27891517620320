import React from "react";
import ServicesFeatureCard from "../services-feature-card/ServicesFeatureCard";

const ServicesMainContent = () => {
  const serviceData = [
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/pedikur.jpg?alt=media&token=2761b4bb-751a-45c0-a4e9-cd242213a173",
      title: "Pedikür",
      shortDesc:
        "Pedikür hizmetimiz ile ayağınızın daha sağlıklı olması için tırnak batması, topuk ve ayak altı ölü derileri, nasırları aldığımız hizmetimizdir.",
      teamData: [
        {
          name: "Batın Ayvaz",
          image:
            "https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/batin.jpg?alt=media&token=cd1ed35d-8ddb-46d0-bbd6-b454c7229c74",
        },
      ],
      longDesc: "Data",
      price: "Data",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/manikur.jpg?alt=media&token=85722ac3-7fad-42c8-a297-c6b20270367e",
      title: "Manikür",
      shortDesc:
        "Ellerin daha sağlıklı ve daha şık gözükmesi için manikür hizmeti ile sizlerleyiz.",
      teamData: [
        {
          name: "Batın Ayvaz",
          image:
            "https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/batin.jpg?alt=media&token=cd1ed35d-8ddb-46d0-bbd6-b454c7229c74",
        },
      ],
      longDesc: "Data",
      price: "Data",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/kupa.jpg?alt=media&token=aa0b48fd-527c-4f26-b5eb-836bc619346e",
      title: "Kupa Masajı",
      shortDesc:
        "Kas gerginliğini azaltıp rahatlatmak ve kan dolaşımını artırarak dokulara daha fazla besin ve oksijen taşınmasını sağlayarak stresi azaltıp, iyileşmeyi hızlandırır ve rahatlama sağlar.",
      teamData: [
        {
          name: "Mustafa Yunak",
          image:
            "https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/mustafa.jpg?alt=media&token=fb35494f-3626-4a4e-b052-94cf1adb89a2",
        },
      ],
      longDesc: "Data",
      price: "Data",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/spor.jpg?alt=media&token=d3d3b65f-8268-4e2d-b334-17a9594af54b",
      title: "Sporcu Masajı",
      shortDesc:
        "Sauna Graston ve Lenf drenaj masajları ile kan dolaşımı ve iyileşme hızını en yüksek seviyeye çıkararak kendimizi sizleri yenilemeye adıyoruz.",
      teamData: [
        {
          name: "Mustafa Yunak",
          image:
            "https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/mustafa.jpg?alt=media&token=fb35494f-3626-4a4e-b052-94cf1adb89a2",
        },
      ],
      longDesc: "Data",
      price: "Data",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/klasik_masaj.jpg?alt=media&token=475ef515-0106-4edf-a0cd-77669a3cb64b",
      title: "Klasik Masaj",
      shortDesc:
        "Bedeninizin rahatlaması ve stresinizi azaltması için masörlerimiz tarafından uygulanan klasik masaj ile kas gerginlikleriniz çözülür, dolaşımınız artar ve enerjiniz yenilenir.",
      teamData: [
        {
          name: "Mustafa Yunak",
          image:
            "https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/mustafa.jpg?alt=media&token=fb35494f-3626-4a4e-b052-94cf1adb89a2",
        },
      ],
      longDesc: "Data",
      price: "Data",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/IMG_2397.jpeg?alt=media&token=81f4e771-ed9f-40c8-80a7-bccfcccbb3e1",
      title: "Saç Boyama",
      shortDesc:
        "Saç boyama servisimizde, deneyimli kuaförlerimizle birlikte size doğal ve canlı renklerden cesur ve farklı tonlara kadar geniş bir yelpazede saç boyama seçenekleri sunuyoruz.",
      teamData: [
        {
          name: "Abdurrahman Özbostan",
          image:
            "https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/abdurrahman.jpg?alt=media&token=cc47bcbe-14ab-413d-91a8-8f18f3684ddb",
        },
        {
          name: "Batın Ayvaz",
          image:
            "https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/batin.jpg?alt=media&token=cd1ed35d-8ddb-46d0-bbd6-b454c7229c74",
        },
      ],
      longDesc: "Data",
      price: "Data",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/sac_sakal.jpeg?alt=media&token=a1784b25-afba-42e2-8a29-9c97bda024c0",
      title: "Saç ve Sakal Kesimi",
      shortDesc:
        "Kaliteli hizmet anlayışımızla, saç ve sakal kesimlerinde istediğiniz tarzı yansıtan özenli çalışmalar yapıyoruz.",
      teamData: [
        {
          name: "Abdurrahman Özbostan",
          image:
            "https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/abdurrahman.jpg?alt=media&token=cc47bcbe-14ab-413d-91a8-8f18f3684ddb",
        },
        {
          name: "Batın Ayvaz",
          image:
            "https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/batin.jpg?alt=media&token=cd1ed35d-8ddb-46d0-bbd6-b454c7229c74",
        },
      ],
      longDesc: "Data",
      price: "Data",
    },
  ];

  return (
    <div>
      <section className="font-chakra py-7 bg-sky-900 text-gray-100">
        <div className="container flex flex-col items-center justify-center p-4 mx-auto sm:p-10">
          <p className="p-2 text-sm font-medium tracking-wider text-center uppercase">
            The Gentleman Studio
          </p>
          <h1 className="text-4xl font-bold leading-none text-center sm:text-5xl">
            Sunduğumuz Hizmetler
          </h1>
          <div className="flex flex-row flex-wrap-reverse justify-center mt-5 mb-8">
            {serviceData.map((service) => (
              <ServicesFeatureCard
                key={service.title}
                image={service.image}
                title={service.title}
                shortDesc={service.shortDesc}
                teamData={service.teamData.map((team) => ({
                  name: team.name,
                  image: team.image,
                }))}
                longDesc={service.longDesc}
                price={service.price}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesMainContent;
