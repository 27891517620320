import React from "react";
import MainpageMainContent from "../mainpage-main-content/MainpageMainContent";
import MainpageSideContent from "../mainpage-side-content/MainpageSideContent";

const Mainpage = () => {
  return (
    <div className=" bg-sky-900">
      <MainpageMainContent />
      <div className="">
        <MainpageSideContent />
      </div>
    </div>
  );
};

export default Mainpage;
