import React from "react";

const MainpageSideContent = () => {
  return (
    <div className="container my-3 mx-auto md:px-6">
      <section className="background-radial-gradient text-center lg:text-left">
        <div className="relative overflow-hidden bg-cover bg-no-repeat bg-[50%] bg-[url('https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/masaj_mainpage.jpg?alt=media&token=8d3032c7-ffdb-4359-ac00-610ce17c5f62')] h-[600px] rounded-2xl">
          <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsla(0,0%,0%,0.55)] bg-fixed">
            <div className="flex h-full items-center justify-center">
              <div className="max-w-[800px] px-6 py-6 text-center text-white md:py-0 md:px-12">
                <h2 className="mb-12 text-5xl font-bold leading-tight tracking-tight md:text-6xl xl:text-7xl font-chakra">
                  Stresi Unut, Yenilenmeye Adım At!
                </h2>
                <p className="text-lg font-chakra">
                  Masaj ile sağlığınızı yenileyerek bedeninizi ve zihninizi
                  dinlendirin, ruhunuzu yeniden canlandırın çünkü sizler için
                  lüks değil bir ihtiyaç.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MainpageSideContent;
