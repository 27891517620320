import React from "react";
import ServicesMainContent from "../services-main-content/ServicesMainContent";

const Services = () => {
  return (
    <div className="bg-sky-900 h-screen">
      <ServicesMainContent />
    </div>
  );
};

export default Services;
