import React from "react";

const LocationMap = () => {
  return (
    <div className="mb-4 container mx-auto pt-12">
      <div className="w-full h-full">
        <iframe
          className="rounded"
          width="100%"
          height="400"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=tr&amp;q=Arabac%C4%B1alan%C4%B1,%20Cadde%2054,%2054.%20Cadde%20D:3-B%20Blok,%20No:%20208,%2054050%20Serdivan/Sakarya+(The%20Gentleman%20Studio)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
          <a href="https://www.maps.ie/distance-area-calculator.html">
            distance maps
          </a>
        </iframe>
      </div>
    </div>
  );
};

export default LocationMap;
