import React from "react";
import TeamMemberToolTip from "../team-member-tool-tip/TeamMemberToolTip";

const ServicesFeatureCard = ({
  image,
  title,
  shortDesc,
  teamData,
  longDesc,
  price,
}) => {
  return (
    <div className="flex m-2">
      <div className="max-w-sm border rounded-lg shadow bg-gray-800 border-gray-700 item-center">
        {/* <a href="#"> */}
        <img className="rounded-t-lg" src={image} alt="" />
        {/* </a> */}
        <div className="p-5">
          {/* <a href="#"> */}
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-white">
            {title}
          </h5>
          {/* </a> */}
          <p className="mb-3 font-normal text-gray-400">{shortDesc}</p>
          <div className="mb-2 text-sm font-medium">
            <p className="items-center text-gray-300">Hizmeti sunan ekibimiz</p>
            <div className="flex">
              {teamData.map((team) => (
                <TeamMemberToolTip
                  key={team.name}
                  name={team.name}
                  image={team.image}
                />
              ))}
            </div>
          </div>
          {/* <a
            href="#"
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
          >
            Daha fazla bilgi
            <svg
              aria-hidden="true"
              className="w-4 h-4 ml-2 -mr-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default ServicesFeatureCard;
