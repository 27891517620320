import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faLocationDot,
  faUsersLine,
  faHouse,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";

const Navigation = ({ activeMenu, onMenuChange }) => {
  const Menus = [
    { name: " Randevu ", icon: faCalendarCheck },
    { name: "  Konum  ", icon: faLocationDot },
    { name: "Anasayfa", icon: faHouse },
    { name: "Ekibimiz", icon: faUsersLine },
    { name: "Hizmetler", icon: faLightbulb },
  ];

  return (
    <div className="bg-neutral-800 px-6 rounded-t-2xl fixed bottom-0 left-0 right-0 font-chakra">
      <ul className="flex justify-center">
        {Menus.map((menu, index) => (
          <li key={index} clasName="w-16">
            <a
              className="flex flex-col text-center pt-6 text-white"
              onClick={() => onMenuChange(index)}
            >
              <span
                className={`text-xl cursor-pointer duration-500 ${
                  index === activeMenu && "-mt-7 "
                }`}
              >
                <span
                  className={`${
                    index === activeMenu && "text-2xl duration-500"
                  }`}
                >
                  <span
                    className={`${
                      index == activeMenu &&
                      "bg-orange-700 -ml-5 border-4 border-neutral-800 h-16 w-16 absolute -top-[21px] rounded-full"
                    }`}
                  >
                    <span className="w-3.5 h-3.5 bg-transparent absolute top-4 -left-[18px] rounded-tr-[11px]"></span>
                    <span className="w-3.5 h-3.5 bg-transparent absolute top-4 -right-[18px] rounded-tl-[11px]"></span>
                    <FontAwesomeIcon className="pt-3" icon={menu.icon} />
                  </span>
                </span>
              </span>
              <span
                className={`${
                  activeMenu === index
                    ? "translate-y-4 duration-700 opacity-100 text-white mt-8 ml-5"
                    : "opacity-0 translate-y-10"
                } `}
              >
                {menu.name}
              </span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Navigation;
