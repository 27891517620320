import React from "react";

const MainpageMainContent = () => {
  return (
    <div class="container -my-3 mx-auto md:px-6">
      <section class="background-radial-gradient mb-8 text-center lg:text-left">
        <div
          className="Image by senivpetro on Freepik"
          class="relative overflow-hidden bg-cover bg-no-repeat bg-[50%] bg-[url('https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/1_mb_coming_soon.jpg?alt=media&token=a44c67ed-3d01-4632-9dff-36f8194ee468')] h-[600px] rounded-b-2xl"
        >
          <div class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsla(0,0%,0%,0.55)] bg-fixed">
            <div class="flex h-full items-center justify-center">
              <div class="max-w-[800px] px-6 py-6 text-center text-white md:py-0 md:px-12">
                <h2 class="mb-12 text-5xl font-bold leading-tight tracking-tight md:text-6xl xl:text-7xl font-chakra">
                  İyi bir imaj <br />
                  <span>iyi ve bakımlı bir saç ile başlar.</span>
                </h2>
                <p class="text-lg font-chakra">
                  The Gentleman Studio olarak saç ve sakal kesimlerindeki
                  uzmanlığımızla, her müşterimize özel ve memnuniyet garantili
                  hizmet sunarak, stilinizi tamamlayacak kusursuz görünümler
                  elde etmenizi sağlıyoruz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MainpageMainContent;
