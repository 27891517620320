import React, { useState } from "react";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Mainpage from "../../components/mainpage/Mainpage";
import Location from "../../components/location/Location";
import Team from "../../components/team/Team";
import Services from "../../components/services/Services";

const Home = () => {
  const [activeMenu, setActiveMenu] = useState(2);

  const handleMenuChange = (index) => {
    if (index === 0) {
      window.location.href = "https://www.randevu.thegentlemanstudio.com";
    } else {
      setActiveMenu(index);
    }
  };
  const renderContent = () => {
    switch (activeMenu) {
      case 1:
        return <Location />;
      case 2:
        return <Mainpage />;
      case 3:
        return <Team />;
      case 4:
        return <Services />;
      default:
        return null;
    }
  };

  return (
    <div className="justify-center bg-sky-900">
      <Header />
      {renderContent()}
      <Navigation activeMenu={activeMenu} onMenuChange={handleMenuChange} />
    </div>
  );
};

export default Home;
