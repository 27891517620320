import React from "react";

const LocationRouteButtons = () => {
  return (
    <div className="container mx-auto font-chakra flex">
      <div className="m-auto">
        <a
          href="https://maps.google.com/maps/dir//The+Gentleman+Studio+Arabac%C4%B1alan%C4%B1+Cadde+54+54.+Cadde+D:3-B+Blok,+No:+208,+54050+Serdivan%2FSakarya/@40.7749262,30.364433,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x14ccb3248b97dd7b:0x4b486ffda3f873e4"
          className="inline-flex mx-2 items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none bg-blue-500 hover:bg-blue-700 focus:ring-blue-800"
          target="_blank"
          rel="noreferrer"
        >
          Google Haritalar
          <svg
            className="w-8 h-8 ml-2 -mr-1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 232597 333333"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            image-rendering="optimizeQuality"
            fill-rule="evenodd"
            clip-rule="evenodd"
          >
            <path
              d="M151444 5419C140355 1916 128560 0 116311 0 80573 0 48591 16155 27269 41534l54942 46222 69232-82338z"
              fill="#1a73e8"
            />
            <path
              d="M27244 41534C10257 61747 0 87832 0 116286c0 21876 4360 39594 11517 55472l70669-84002-54942-46222z"
              fill="#ea4335"
            />
            <path
              d="M116311 71828c24573 0 44483 19910 44483 44483 0 10938-3957 20969-10509 28706 0 0 35133-41786 69232-82313-14089-27093-38510-47936-68048-57286L82186 87756c8166-9753 20415-15928 34125-15928z"
              fill="#4285f4"
            />
            <path
              d="M116311 160769c-24573 0-44483-19910-44483-44483 0-10863 3906-20818 10358-28555l-70669 84027c12072 26791 32159 48289 52851 75381l85891-102122c-8141 9628-20339 15752-33948 15752z"
              fill="#fbbc04"
            />
            <path
              d="M148571 275014c38787-60663 84026-88210 84026-158728 0-19331-4738-37552-13080-53581L64393 247140c6578 8620 13206 17793 19683 27900 23590 36444 17037 58294 32260 58294 15172 0 8644-21876 32235-58320z"
              fill="#34a853"
            />
          </svg>
        </a>
      </div>
      <div className="m-auto">
        <a
          href="https://yandex.com.tr/harita/-/CDV77es"
          className="inline-flex mx-2 items-center px-3 py-2 text-sm font-medium text-center rounded-lg focus:ring-4 focus:outline-none bg-yellow-400 text-black hover:bg-neutral-800 hover:text-white focus:ring-black"
          target="_blank"
          rel="noreferrer"
        >
          Yandex Haritalar
          <svg
            className="w-8 h-8 ml-2 -mr-1"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 1C4.6862 1 2 3.6862 2 7C2 8.6563 2.6711 10.156 3.7565 11.2417C4.8422 12.328 7.4 13.9 7.55 15.55C7.57249 15.7974 7.7516 16 8 16C8.2484 16 8.42751 15.7974 8.45 15.55C8.6 13.9 11.1578 12.328 12.2435 11.2417C13.3289 10.156 14 8.6563 14 7C14 3.6862 11.3138 1 8 1Z"
              fill="#FF4433"
            />
            <path
              d="M8.00002 9.10015C9.15982 9.10015 10.1 8.15994 10.1 7.00015C10.1 5.84035 9.15982 4.90015 8.00002 4.90015C6.84023 4.90015 5.90002 5.84035 5.90002 7.00015C5.90002 8.15994 6.84023 9.10015 8.00002 9.10015Z"
              fill="white"
            />

            <path
              d="M151444 5419C140355 1916 128560 0 116311 0 80573 0 48591 16155 27269 41534l54942 46222 69232-82338z"
              fill="#1a73e8"
            />
            <path
              d="M27244 41534C10257 61747 0 87832 0 116286c0 21876 4360 39594 11517 55472l70669-84002-54942-46222z"
              fill="#ea4335"
            />
            <path
              d="M116311 71828c24573 0 44483 19910 44483 44483 0 10938-3957 20969-10509 28706 0 0 35133-41786 69232-82313-14089-27093-38510-47936-68048-57286L82186 87756c8166-9753 20415-15928 34125-15928z"
              fill="#4285f4"
            />
            <path
              d="M116311 160769c-24573 0-44483-19910-44483-44483 0-10863 3906-20818 10358-28555l-70669 84027c12072 26791 32159 48289 52851 75381l85891-102122c-8141 9628-20339 15752-33948 15752z"
              fill="#fbbc04"
            />
            <path
              d="M148571 275014c38787-60663 84026-88210 84026-158728 0-19331-4738-37552-13080-53581L64393 247140c6578 8620 13206 17793 19683 27900 23590 36444 17037 58294 32260 58294 15172 0 8644-21876 32235-58320z"
              fill="#34a853"
            />
          </svg>
        </a>
      </div>
      <div className="m-auto">
        <a
          href="https://yandex.com.tr/harita/-/CDV77es"
          className="inline-flex mx-2 items-center px-3 py-2 text-sm font-medium text-center rounded-lg focus:ring-4 focus:outline-none bg-white text-black hover:bg-neutral-800 hover:text-white focus:ring-black"
          target="_blank"
          rel="noreferrer"
        >
          Apple Haritalar
          <svg
            className="w-8 h-8 ml-2 -mr-"
            viewBox="0 0 256 315"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
          >
            <g>
              <path
                d="M213.803394,167.030943 C214.2452,214.609646 255.542482,230.442639 256,230.644727 C255.650812,231.761357 249.401383,253.208293 234.24263,275.361446 C221.138555,294.513969 207.538253,313.596333 186.113759,313.991545 C165.062051,314.379442 158.292752,301.507828 134.22469,301.507828 C110.163898,301.507828 102.642899,313.596301 82.7151126,314.379442 C62.0350407,315.16201 46.2873831,293.668525 33.0744079,274.586162 C6.07529317,235.552544 -14.5576169,164.286328 13.147166,116.18047 C26.9103111,92.2909053 51.5060917,77.1630356 78.2026125,76.7751096 C98.5099145,76.3877456 117.677594,90.4371851 130.091705,90.4371851 C142.497945,90.4371851 165.790755,73.5415029 190.277627,76.0228474 C200.528668,76.4495055 229.303509,80.1636878 247.780625,107.209389 C246.291825,108.132333 213.44635,127.253405 213.803394,167.030988 M174.239142,50.1987033 C185.218331,36.9088319 192.607958,18.4081019 190.591988,0 C174.766312,0.636050225 155.629514,10.5457909 144.278109,23.8283506 C134.10507,35.5906758 125.195775,54.4170275 127.599657,72.4607932 C145.239231,73.8255433 163.259413,63.4970262 174.239142,50.1987249"
                fill="#000000"
              ></path>
            </g>
          </svg>
        </a>
      </div>
    </div>
  );
};

export default LocationRouteButtons;
