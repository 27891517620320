import React from "react";
import LocationMap from "../locationmap/LocationMap";
import LocationRouteButtons from "../location-route-buttons/LocationRouteButtons";

const Location = () => {
  return (
    <div className="h-screen">
      <LocationMap />

      <LocationRouteButtons />
    </div>
  );
};

export default Location;
