import './App.css';
import Home from './pages/home/Home';

function App() {
  return (
    <div className="">
      <Home />
      {/* Coming Soon Page Code */}
      {/* <section class="h-screen bg-cover bg-no-repeat bg-[50%] bg-[url('https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/coming_soon.jpg?alt=media&token=36ee309f-dddd-4154-a51f-49ce4a6967a2')] background-radial-gradient">
        <div class="flex h-full w-full items-center justify-center container mx-auto px-8 bg-[hsla(0,0%,25%,0.6)]">
          <div class="max-w-2xl text-center">
            <img class="mx-auto h-100 w-auto mb-20" src="https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/logo-tgs.png?alt=media&token=f616ffa9-3c4e-470e-ab19-c078946fd304" alt="Workflow" />
            <h1 class="text-3xl sm:text-5xl capitalize tracking-widest text-white lg:text-7xl font-chakra font-bold">Yakında Sizlerleyiz</h1>

            <p class="mt-6 lg:text-lg text-white font-chakra">Sitemiz şuan bakımdadır. En kısa sürede hizmet vermek için can atıyoruz.</p>

            <div class="mt-8 flex flex-col space-y-3 sm:-mx-2 sm:flex-row sm:justify-center sm:space-y-0">

              <a class="transform rounded-md bg-blue-700 px-8 py-2 text-lg font-medium capitalize tracking-wide text-white transition-colors duration-200 hover:bg-blue-600 focus:bg-blue-600 focus:outline-none sm:mx-2" href="tel:+905326130354">İletişim İçin Tıklayın</a>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default App;
