import React, { useState } from "react";

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <div className="sticky top-0 z-10">
      <nav className="relative flex flex-wrap justify-center px-2 py-3 bg-neutral-800 mb-3">
        <div className="container px-4 mx-auto flex flex-wrap justify-center">
          <div className="w-full relative flex justify-center lg:w-auto lg:static lg:justify-center">
            <a
              rel="noopener noreferrer"
              href="https://www.instagram.com/the.gentleman.studio/"
              target="_blank"
              title="TikTok"
              className="text-white hover:text-orange-700 mt-6 mr-5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="800px"
                height="800px"
                fill="currentColor"
                class="bi bi-tiktok"
                viewBox="-9 -1 24 24"
                className="w-10 h-10"
                preserveAspectRatio="xMinYMin"
              >
                <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z"></path>
              </svg>
            </a>

            <a
              className="text-sm font-bold leading-relaxed inline-block whitespace-nowrap uppercase text-white items-center"
              href="https://www.thegentlemanstudio.com"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/logo_circle.png?alt=media&token=c570d572-da1e-4a69-b445-69d2cc979a24"
                alt="logo"
                className="h-32 w-32 -mb-20"
              />
            </a>

            <a
              rel="noopener noreferrer"
              href="https://www.instagram.com/the.gentleman.studio/"
              target="_blank"
              title="Instagram"
              className="text-white hover:text-orange-700 mt-5 ml-5"
            >
              <svg
                fill="currentColor"
                width="800px"
                height="800px"
                viewBox="0 0 24 23"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMinYMin"
                class="jam jam-instagram "
                className="w-10 h-10"
              >
                <path d="M14.017 0h-8.07A5.954 5.954 0 0 0 0 5.948v8.07a5.954 5.954 0 0 0 5.948 5.947h8.07a5.954 5.954 0 0 0 5.947-5.948v-8.07A5.954 5.954 0 0 0 14.017 0zm3.94 14.017a3.94 3.94 0 0 1-3.94 3.94h-8.07a3.94 3.94 0 0 1-3.939-3.94v-8.07a3.94 3.94 0 0 1 3.94-3.939h8.07a3.94 3.94 0 0 1 3.939 3.94v8.07z" />
                <path d="M9.982 4.819A5.17 5.17 0 0 0 4.82 9.982a5.17 5.17 0 0 0 5.163 5.164 5.17 5.17 0 0 0 5.164-5.164A5.17 5.17 0 0 0 9.982 4.82zm0 8.319a3.155 3.155 0 1 1 0-6.31 3.155 3.155 0 0 1 0 6.31z" />
                <circle cx="15.156" cy="4.858" r="1.237" />
              </svg>
            </a>
            {/* <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button> */}
          </div>
          {/* <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="https://www.instagram.com/abdurrahmanozbostan/"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square text-lg leading-lg text-white opacity-75"></i>
                  <span className="ml-2">Instagram</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="https://twitter.com/oemreturhan"
                  target="_blank"
                >
                  <i className="fab fa-twitter text-lg leading-lg text-white opacity-75"></i>
                  <span className="ml-2">Twitter</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="#pablo"
                >
                  <i className="fab fa-pinterest text-lg leading-lg text-white opacity-75"></i>
                  <span className="ml-2">Pin</span>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </nav>
    </div>
  );
};

export default Header;
