import React from "react";
import TeamMenu from "../teammenu/TeamMenu";

const Team = () => {
  return (
    <div className="h-screen">
      <TeamMenu />
    </div>
  );
};

export default Team;
