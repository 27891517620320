import React from "react";

const TeamMemberToolTip = ({ name, image }) => {
  return (
    <div>
      <div className="flex items-center m-2">
        <div className="relative flex flex-col items-center group">
          <img
            data-tooltip-target="tooltip-bonnie"
            className="w-10 h-10 rounded"
            src={image}
            alt="Medium avatar"
          />
          <div className="absolute bottom-0 flex-col items-center hidden mb-6 group-hover:flex">
            <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg">
              {name}
            </span>
            <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberToolTip;
